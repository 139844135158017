import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './locales/i18n';
import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

const app = createApp(App);

app.use(router);
app.use(i18n);
app.mount('#app')
