import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import br from "@/locales/br.json";
import pt from "@/locales/pt.json";

function loadLocaleMessages() {
  const locales = [{en: en}, {br: br}, {pt: pt}];
  const messages = {};
  locales.forEach(lang => {
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}

export default createI18n({
  locale: "br",
  fallbackLocale: "en",
  messages: loadLocaleMessages()
})