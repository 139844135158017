<script setup>
import { onMounted } from "vue";
import BackIcon from "vue-material-design-icons/ArrowLeftThin.vue";
import DotsIcon from "vue-material-design-icons/DotsHorizontal.vue";
import SettingsIcon from "vue-material-design-icons/TuneVertical.vue";
import SearchIcon from "vue-material-design-icons/Magnify.vue";
import AppIcon from "vue-material-design-icons/RobotHappy.vue";
import PlatformIcon from "vue-material-design-icons/MonitorCellphone.vue";
import CodeIcon from "vue-material-design-icons/CodeJson.vue";
import TagIcon from "vue-material-design-icons/Xml.vue";
import PollIcon from "vue-material-design-icons/Poll.vue";
import ListIcon from "vue-material-design-icons/TextBoxOutline.vue";
import PowerIcon from "vue-material-design-icons/Power.vue";
import PplIcon from "vue-material-design-icons/AccountMultipleOutline.vue";

onMounted(() => {
  const tiles = document.querySelector(".tiles");
  const people = document.querySelector(".people");
  setTimeout(() => {
    tiles.classList.add("start");
  }, 850);
  setTimeout(() => {
    people.classList.add("start");
  }, 2000);
});
</script>
<template>
  <div class="shell">
    <div class="header flex flex-between">
      <BackIcon :size="30" fillColor="#a1a1A1" />
      <DotsIcon :size="30" fillColor="#a1a1A1" />
    </div>
    <div class="title-holder flex flex-between">
      <div class="name">{{ $t("Atualize sua empresa") }}</div>
      <SettingsIcon :size="26" fillColor="#555" />
    </div>
    <div class="tiles">
      <div style="--i: 0" class="tile">
        <div class="value">{{ $t("Crie") }}</div>
        <div class="label">{{ $t("Aplicativos") }}</div>
      </div>
      <div style="--i: 1" class="tile">
        <div class="value">{{ $t("Conecte") }}</div>
        <div class="label">{{ $t("Plataformas") }}</div>
      </div>
      <div style="--i: 2" class="tile">
        <div class="value">{{ $t("Gerencie") }}</div>
        <div class="label">{{ $t("Tudo") }}</div>
      </div>
    </div>
    <div class="divider"></div>

    <div class="title-holder flex flex-between">
      <div class="name">{{ $t("Tudo ao seu alcance") }}</div>
      <SearchIcon :size="26" fillColor="#555" />
    </div>

    <div class="people">
      <div class="item">
        <div class="avatar">
          <AppIcon fillColor="#3273d6" />
        </div>
        <div class="content">
          <div class="name">{{ $t("Aplicativos") }}</div>
          <div class="progress">
            <div style="--i: 0" class="fill"></div>
          </div>
        </div>
        <div style="--i: 0" class="info">
          <div class="total">Check!</div>
          <div class="label">✅</div>
        </div>
      </div>
      <div class="item">
        <div class="avatar">
          <PlatformIcon fillColor="#23b94d" />
        </div>
        <div class="content">
          <div class="name">{{ $t("Plataformas") }}</div>
          <div class="progress">
            <div style="--i: 1" class="fill two"></div>
          </div>
        </div>
        <div style="--i: 1" class="info">
          <div class="total">Check!</div>
          <div class="label">✅</div>
        </div>
      </div>
      <div class="item">
        <div class="avatar">
          <CodeIcon fillColor="#644fe9" />
        </div>
        <div class="content">
          <div class="name">{{ $t("Integrações") }}</div>
          <div class="progress">
            <div style="--i: 2" class="fill three"></div>
          </div>
        </div>
        <div style="--i: 2" class="info">
          <div class="total">Check!</div>
          <div class="label">✅</div>
        </div>
      </div>
      <div class="item">
        <div class="avatar">
          <TagIcon fillColor="#d22597" />
        </div>
        <div class="content">
          <div class="name">Websites</div>
          <div class="progress">
            <div style="--i: 3" class="fill four"></div>
          </div>
        </div>
        <div style="--i: 3" class="info">
          <div class="total">Check!</div>
          <div class="label">✅</div>
        </div>
      </div>
    </div>
    <div class="app-navbar">
      <div class="item">
        <PollIcon fillColor="#a1a1a1" />
      </div>
      <div class="item">
        <ListIcon fillColor="#a1a1a1" />
      </div>
      <div class="item">
        <PowerIcon fillColor="var(--rubik-blue)" :size="34" />
      </div>
      <div class="item">
        <PplIcon fillColor="#a1a1a1" />
      </div>
      <div class="item">
        <DotsIcon fillColor="#a1a1a1" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.shell {
  position: absolute;
  width: 100%;
  max-width: 440px;
  padding: 2rem 2rem calc(2rem + 70px) 2rem;
  background-color: #fff;
  border-radius: 1rem;
  bottom: -80px;
  left: 100px;
  z-index: 1;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  .title-holder {
    margin: 1.2rem 0;
    .name {
      font-size: 1.5rem;
    }
  }
}
.tiles {
  position: relative;
  height: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
  .tile {
    position: absolute;
    width: 150px;
    padding: 1.75rem 1.5rem;
    border-radius: 0.75rem;
    background: linear-gradient(125deg, #108bff, #0e77da);
    box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.1);
    left: 0;
    transform: scale(0) translateX(calc(165px * var(--i)));
    transition: transform 0.8s ease;
    transition-delay: calc(0.4s * var(--i));
    .value {
      font-family: fontBold;
      font-size: 1.7rem;
      color: #fff;
      letter-spacing: 2px;
    }
    .label {
      font-size: 1.15rem;
      color: #fff;
      margin-top: 4px;
      letter-spacing: 1px;
    }
    &:nth-child(2) {
      background: linear-gradient(125deg, #a85eed, #8e4bcc);
    }
    &:nth-child(3) {
      background: linear-gradient(125deg, #f34064, #db3455);
    }
  }
  &.start {
    .tile {
      transform: scale(1) translateX(calc(165px * var(--i)));
    }
  }
}

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  margin: 2.5rem auto;
}

.people {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    .avatar {
      width: 44px;
      height: 44px;
      border: 1px solid #ddd;
      border-radius: 100%;

      display: grid;
      place-items: center;
    }
    .content {
      position: relative;
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 1.25rem;
      }
      .progress {
        position: relative;
        width: 100%;
        padding: 2px;
        border-radius: 0.5rem;
        background-color: #eee;
        margin-top: 5px;
        .fill {
          position: relative;
          width: 0%;
          height: 11px;
          background: linear-gradient(90deg, #84b2f8, #3273d6);
          border-radius: 0.5rem;
          transition: width 1.56s ease;
          transition-delay: calc(0.36s * var(--i));
          &.two {
            background: linear-gradient(90deg, #4bdac9, #23b94d);
          }
          &.three {
            background: linear-gradient(90deg, #fa4cb4, #644fe9);
          }
          &.four {
            background: linear-gradient(90deg, #f3b558, #d22597);
          }
        }
      }
    }
    .info {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: auto;
      gap: 0.5rem;
      opacity: 0;
      transition: opacity 0.5s ease, transform 0.5s ease;
      transform: scale(0);
      transition-delay: calc(0.7s * var(--i));
      .total {
        color: #158af7;
        font-size: 1rem;
      }
      .label {
        font-size: 1rem;
        color: #aaa;
      }
    }
  }
  &.start {
    .fill {
      width: 100% !important;
    }
    .info {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.app-navbar {
  position: absolute;
  width: 100%;
  height: 58px;
  border-radius: 0 0 1rem 1rem;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #eee;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  .item {
    position: relative;
    height: 100%;
    cursor: pointer;
    display: grid;
    place-items: center;
    &:hover {
      * {
        transition: transform 0.2s linear;
        transform: scale(1.25);
      }
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 70px;
    height: 2px;
    background-color: var(--rubik-blue);
    top: -1px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>
