<template>
  <router-view />
  <div v-show="!cookiesAccepted" class="container">
    <div class="terms">
      <p>
        Utilizamos cookies para melhorar sua experiência, desempenho, analisar
        como você interage em nosso site e personalizar conteúdo. Ao utilizar
        nosso site, você concorda com o uso de cookies.
      </p>
      <div @click="acceptCookies" class="btn">
        <span>Ok, entendi!</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cookiesAccepted: false,
    };
  },
  created() {
    if (localStorage.cookiesAccepted) this.cookiesAccepted = true;
  },
  methods: {
    acceptCookies() {
      this.cookiesAccepted = true;
      localStorage.cookiesAccepted = true;
    },
  },
};
</script>
<style lang="scss">
:root {
  --rubik-blue: #0087fe;
  --rubik-green: #00ad32;
  --rubik-yellow: #febe00;
  --rubik-red: #fe3d25;
  --silver: #f8f8f8;
  --silver1: #e8e8e8;
  --dark1: #1d1d1d;
  --dark2: #282828;
  --dark3: #3a3a3a;
  --dark4: #4f4f4f;
  --purple1: #bf2fcb;
}

.terms {
  position: fixed;
  width: 90%;
  max-width: 1440px;
  padding: 20px;
  background-color: var(--dark2);
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999999;
  @media screen and (max-width: 900px) {
    display: block;
    .btn {
      margin-top: 15px;
      margin-left: initial;
    }
  }
  p {
    color: var(--silver);
    font-size: 1em;
    line-height: 1.5em;
  }
  .btn {
    min-width: 160px;
    margin-left: 20px;
    span {
      font-size: 1em;
      padding: 14px 0 !important;
    }
  }
}

.color-primary {
  color: var(--primary) !important;
}

.color-secondary {
  color: var(--secondary) !important;
}

.color-dark1 {
  color: var(--dark1) !important;
}
.color-dark2 {
  color: var(--dark2) !important;
}
.color-dark4 {
  color: var(--dark4) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}
.bg-rubik-yellow {
  background-color: var(--rubik-yellow) !important;
}

*:not(path) {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 13px;
  font-family: fontRegular;
  color: var(--dark4);
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  min-width: 100vw;
}

.pointer {
  cursor: pointer;
}

.clearfix {
  clear: both !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.general-text {
  font-family: fontLight;
  font-size: 1.15em;
  color: #888888;
  margin-top: 25px;
  line-height: 1.75em;
  letter-spacing: 1px;
}

.divider {
  position: relative;
  width: 90%;
  height: 1px;
  background-color: #e9e9e9;
  margin: 65px auto;
}

.text-white {
  color: #fff !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-dark1 {
  color: var(--dark1) !important;
}
.text-dark2 {
  color: var(--dark2) !important;
}
.text-dark3 {
  color: var(--dark3) !important;
}
.text-dark4 {
  color: var(--dark4) !important;
}
.text-dark5 {
  color: var(--dark5) !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

ul {
  list-style: none;
}

.btn {
  position: relative;
  padding: 14px 28px;
  border-radius: 4px;
  display: flex;
  background-color: var(--rubik-blue);
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #0675d6;
  }
  &.bg-primary > span {
    color: #fff;
  }
  span {
    font-family: fontRegular;
    color: #fff;
    font-size: 1.15em;
    letter-spacing: 2px;
    transition: color 0.2s ease;
  }
  &.border {
    padding: 16px 22px;
    border: 1px solid var(--rubik-green);
    background-color: transparent;
    box-shadow: none;
    span {
      color: var(--rubik-green);
    }
  }
  &.bg-rubik-yellow {
    &:hover {
      background-color: #e3ab03 !important;
    }
  }
}

.rotate180 {
  transition: transform 0.2s ease;
  transform: rotate(-180deg);
}

section {
  position: relative;
  width: 100vw;
}

section:not(.intro) {
  padding: 85px 0;
}

.section-call {
  text-align: center;
  color: var(--primary);
  margin-bottom: -5px;
  font-size: 1.4em;
}

.section-title {
  position: relative;
  text-align: center;
  font-size: 2.6em;
  margin-bottom: 62px;
}

.section-title::after {
  position: absolute;
  content: "";
  width: 96px;
  height: 3px;
  background-color: var(--primary);
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -7px;
}

section.intern-header {
  background-color: var(--gray1);
  padding: 45px 0 2px 0;
}

.bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 99;
}

.description *:not(h1, h2, h3, h4, h5, strong) {
  font-size: 1em;
}

.post .content-holder .description h1,
.post .content-holder .description h1 *,
.post .content-holder .description h2,
.post .content-holder .description h2 *,
.post .content-holder .description h3,
.post .content-holder .description h3 *,
.post .content-holder .description h4,
.post .content-holder .description h4 *,
.post .content-holder .description strong {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.post .content-holder .description ul {
  list-style: disc;
  list-style-position: inside;
}

.loading-colors {
  position: relative;
  display: block;
  height: 50px;
  width: 50px;
  margin: 0 auto;
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 6px var(--rubik-green) solid;
  border-right: 6px var(--rubik-blue) solid;
  border-bottom: 6px var(--rubik-red) solid;
  border-left: 6px var(--rubik-yellow) solid;
  border-radius: 50%;
  animation: spin6 1s infinite linear;
}
@keyframes spin6 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-name: rotate-forever;
  animation-timing-function: linear;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.title {
  font-family: fontLight;
  font-size: 3.4em;
  line-height: 1.1em;
  font-weight: lighter;
  color: #fff;
  background-image: linear-gradient(90.09deg, #1f2e41 0%, #0085f8 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  &.color2 {
    background-image: linear-gradient(90.09deg, #0b3caa 0%, #f54c4c 40%);
  }
  &.color2 {
    background-image: linear-gradient(90.09deg, #bf3cfc 0%, #ff6767 40%);
  }
}
</style>
