<script setup>
import { ref, onMounted } from "vue";

import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import ChevronRight from "vue-material-design-icons/ChevronRight";

import "swiper/css";
import "swiper/css/bundle";
import Swiper, { Navigation, Pagination } from "swiper";
Swiper.use([Navigation, Pagination]);

onMounted(() => {
  initProjectSlider();
});

const initProjectSlider = () => {
  setTimeout(() => {
    const slider = new Swiper("#projectSlider", {
      slidesPerView: 1,
      spaceBetween:
        window.innerWidth < 720
          ? 10
          : window.innerWidth > 720 && window.innerWidth < 1160
          ? 30
          : 100,
      loop: false,
      grabCursor: true,
      autoHeight: true,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      navigation: {
        nextEl: "#sliderNext",
        prevEl: "#sliderPrev",
      },
    });
  }, 100);
};

const projects = ref([
  {
    title: "Nutrixy",
    subtitle:
      "Nutrixy é uma plataforma de Nutrição completo com funcionalidades únicas para os nutricionistas e seus pacientes.",
    subtitleEn:
      "Nutrixy is a complete Nutrition Platform with unique features for nutritionists and their patients.",
    subtitlePt:
      "Nutrixy é uma plataforma completa de Nutrição com características únicas para nutricionistas e seus pacientes.",
    description:
      "Plataforma robusta por assinatura focada em facilitar o trabalho dos nutricionistas, com agenda de consultas, antropometria, planos alimentares, gerenciamento de pacientes, cálculos nutricionais especializados, entre outras coisas! Conta também com aplicativos Android e iOS para os pacientes.",
    descriptionEn:
      "Robust subscription platform focused on facilitating the work of nutritionists, with appointment schedule, anthropometry, meals planning, patients management, specialized nutritional calculations and much more! There is also an Android and iOS app for patients.",
    descriptionPt:
      "Robusta plataforma de assinatura focada em facilitar o trabalho dos nutricionistas, com agendamento de consultas, antropometria, planos de refeições, gestão de pacientes, cálculos nutricionais especializados, entre outras coisas! Ele também tem aplicativos Android e iOS para pacientes.",
    image: require("@/assets/images/projects/nutrixy.jpg"),
    clientLogo: require("@/assets/images/projects/nutrixy-logo.svg"),
    platforms: [
      { name: "Android", color: "#1fbe55" },
      { name: "iOS", color: "#676767" },
      { name: "Web", color: "#f32f4d" },
    ],
  },
  {
    title: "CheckFolha",
    subtitle:
      "Ferramenta poderosa para agricultores e técnicos que desejam conhecer a fundo a nutrição de suas lavouras.",
    subtitleEn:
      "Powerful tool for farmers and technicians who want to know in depth the nutrition of their crops.",
    subtitlePt:
      "Poderosa ferramenta para agricultores e técnicos que querem conhecer a nutrição das suas culturas em profundidade.",
    description:
      "O app interpreta análises de 13 culturas diferentes fornecendo um relatório claro e objetivo dos principais problemas nutricionais encontrados, assim como as ações corretivas necessárias para atingir o máximo em produtividade. Para maior praticidade, o app funciona também offline, podendo ser utilizado no meio do campo em tempo real. Uma vez com internet, os dados são sincronizados na nuvem automaticamente.",
    descriptionEn:
      "The app interprets analyzes of 13 different crops, providing a clear and objective report of the main nutritional problems found, as well as the corrective actions necessary to achieve maximum productivity. For greater practicality, the app also works offline, and can be used in the middle of the field in real time. Once connected to the internet, the data is automatically synchronized in the cloud.",
    descriptionPt:
      "O aplicativo interpreta análises de 13 culturas diferentes, fornecendo um relatório claro e objetivo dos principais problemas nutricionais encontrados, bem como as ações corretivas necessárias para alcançar a máxima produtividade. Para maior praticidade, o aplicativo também funciona offline, podendo ser usado no meio de campo em tempo real. Depois de ter internet, os dados são sincronizados na nuvem automaticamente.",
    image: require("@/assets/images/projects/checkfolha.jpg"),
    clientLogo: require("@/assets/images/projects/checkfolha-logo.png"),
    platforms: [
      { name: "Android", color: "#1fbe55" },
      { name: "iOS", color: "#676767" },
      { name: "Web", color: "#f32f4d" },
    ],
  },
  {
    title: "Cipatex",
    subtitle:
      "O Cipatex Catálogo traz de maneira simples e organizada todos os produtos da marca.",
    subtitleEn:
      "'Cipatex Catálogo' brings in a simple and organized way all the brand's products.",
    subtitlePt:
      "O 'Catálogo Cipatex' traz todos os produtos da marca de forma simples e organizada.",
    description:
      "No aplicativo o usuário pode criar suas listas personalizadas de produtos, compartilhar nas redes sociais e exportar para pdf. Através do app é possível também tirar uma foto do seu ambiente para o aplicativo analisar e recomendar quais produtos melhor combinam com ele!",
    descriptionEn:
      "In the application, users can create their own personalized lists of products, share them on social networks and export them to PDF. Inside the app, users can also take a picture of their environment, so the app can analyze and recommend which products best match it according to the furnitures and style!",
    descriptionPt:
      "No aplicativo, o usuário pode criar suas listas de produtos personalizadas, compartilhar nas redes sociais e exportar para pdf. Através do app também é possível tirar uma foto do seu ambiente para o app analisar e recomendar quais produtos melhor se adequam a ele!",
    image: require("@/assets/images/projects/cipatex.jpg"),
    clientLogo: require("@/assets/images/projects/cipatex-logo.png"),
    platforms: [
      { name: "Android", color: "#1fbe55" },
      { name: "iOS", color: "#676767" },
      { name: "Web", color: "#f32f4d" },
    ],
  },
  {
    title: "Transitions",
    subtitle:
      "O Transitions Lab Network é um sistema para acompanhamento da conversão de vendas dos consultores com seus clientes.",
    subtitleEn:
      "The Transitions Lab Network is a system for monitoring the sales conversion of consultants with their clients.",
    subtitlePt:
      "O Transitions Lab Network é um sistema para acompanhar a conversão de vendas de consultores com seus clientes.",
    description:
      "Nele, os consultores cadastram todos os dados de vendas de suas lojas e conseguem estimar valores de faturamento e propor novas metas de vendas com base nos dados já existentes. O sistema também proporciona um melhor controle sobre as ações de marketing que já foram executadas em cada loja, como treinamentos, fornecimento de materiais de apoio e muito mais!",
    descriptionEn:
      "Consultants can register all sales data from their stores and are able to estimate billing values ​​and propose new sales targets based on existing data. The system also provides better control over marketing actions that have already been carried out in each store, such as training, provision of support materials and much more!",
    descriptionPt:
      "Nele, os consultores registram todos os dados de vendas de suas lojas e são capazes de estimar valores de receita e propor novas metas de vendas com base nos dados existentes. O sistema também proporciona um melhor controle sobre as ações de marketing que já foram realizadas em cada loja, como treinamentos, fornecimento de materiais de apoio e muito mais!",
    image: require("@/assets/images/projects/transitions.jpg"),
    clientLogo: require("@/assets/images/projects/transitions-logo.png"),
    platforms: [{ name: "Web", color: "#f32f4d" }],
  },
  // {
  //   title: "BYD Dahruj",
  //   subtitle: "Portal da BYD Dahruj no Brasil.",
  //   subtitleEn: "BYD Dahruj Portal in Brazil",
  //   description:
  //     "Portal da concessionária BYD Dahruj no Brasil, com apresentação dos veículos elétricos, integração de ofertas, integração com o sistema de estoque de veículos seminovos. Possui também um painel administrataivo com gerenciamento de conteúdo, dashboard com métricas e mais!",
  //   descriptionEn:
  //     "Portal of the BYD Dahruj dealership in Brazil, with the presentation of electric vehicles, integration of offers, integration with the used vehicle inventory system. It also has an administrative panel with content management, dashboard with metrics and more!",
  //   image: require("@/assets/images/projects/byd.jpg"),
  //   clientLogo: require("@/assets/images/projects/byd-logo.png"),
  //   platforms: [{ name: "Web", color: "#f32f4d" }],
  // },
  {
    title: "Nissan Dahruj",
    subtitle: "Portal da maior concessionária Nissan da América Latina.",
    subtitleEn: "Portal of the largest Nissan dealership in Latin America.",
    subtitlePt: "Portal da maior concessionária Nissan da América Latina.",
    description:
      "O portal oferece informações sobre os veículos, ofertas sempre atualizadas, agendamento de Test Drive, agendamento de revisões e integração com o sistema de veículos seminovos da concessionária. Conta também com um CMS robusto e prático para gerenciar o portal, bem como métricas importantes de uso.",
    descriptionEn:
      "The portal offers information about the vehicles, always up-to-date offers, test drive scheduling, service scheduling and integration with the dealership's used vehicles system. It also has a robust and practical CMS to manage the portal, as well as important usage metrics.",
    descriptionPt:
      "O portal oferece informações sobre os veículos, ofertas sempre atualizadas, agendamento de Test Drive, agendamento de serviços e integração com o sistema de veículos usados da concessionária. Ele também possui um CMS robusto e prático para gerenciar o portal, bem como importantes métricas de uso.",
    image: require("@/assets/images/projects/nissan.jpeg"),
    clientLogo: require("@/assets/images/projects/nissan-logo.webp"),
    platforms: [{ name: "Web", color: "#f32f4d" }],
  },
]);
</script>

<template>
  <section class="showcase">
    <img class="rocket" src="@/assets/images/rocket.png" alt="foguete" />
    <div class="container">
      <div class="title-holder">
        <h1 class="title color2 mobile-hidden">
          {{ $t("Conheça alguns de nossos projetos") }}
        </h1>
        <h1 class="title color2 desktop-hidden tablet-hidden">
          {{ $t("Alguns de nossos projetos") }}
        </h1>
        <div class="sliders">
          <div id="sliderPrev" class="slider-control prev">
            <ChevronLeft fillColor="#fff" :size="36" />
          </div>
          <div id="sliderNext" class="slider-control next">
            <ChevronRight fillColor="#fff" :size="36" />
          </div>
        </div>
      </div>
      <div class="swiper-container" id="projectSlider">
        <div class="swiper-wrapper">
          <div
            v-for="(project, index) in projects"
            :key="index"
            class="card mt-2 shadow-default swiper-slide"
          >
            <div
              class="img-holder"
              :style="`background-image:url(${project.image});`"
            ></div>
            <div class="card-content">
              <div class="title-holder">
                <img :src="project.clientLogo" alt="logo do cliente" />
                <!-- <h1 class="title-card">{{ project.title }}</h1> -->
              </div>
              <p class="general-text">
                {{
                  $i18n.locale == "br" ? project.subtitle : project.subtitleEn
                }}
              </p>
              <p class="general-text">
                {{
                  $i18n.locale == "br"
                    ? project.description
                    : project.descriptionEn
                }}
              </p>
              <div class="platform-holder mt-2">
                <span class="platform-title">{{ $t("Plataformas") }}</span>
                <div class="platforms">
                  <div
                    v-for="(platform, index) in project.platforms"
                    :key="index"
                    class="platform"
                    :style="`background-color: ${platform.color}`"
                  >
                    {{ platform.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="projectPagination"
          class="swiper-pagination mobile-hidden"
        ></div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
section.showcase {
  padding-top: 115px;
  padding-bottom: 0px !important;
  background: radial-gradient(100% 100% at 50% 100%, #1b2735 0%, #090a0f 100%);
  @media only screen and (max-width: 720px) {
    padding-top: 70px;
  }
}

.rocket {
  position: absolute;
  top: -35px;
  left: 0;
  width: 160px;
  pointer-events: none;
  @media only screen and (max-width: 1139px) {
    width: 110px;
  }
  @media only screen and (max-width: 720px) {
    left: initial;
    top: -45px;
    right: -20px;
    transform: scaleX(-1);
  }
}

.swiper-pagination {
  bottom: -80px !important;
}

.title-holder {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sliders {
  display: flex;
  width: 90px;
  justify-content: space-between;
  align-items: center;
  .slider-control {
    position: relative;
    margin: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    svg {
      font-size: 1.2em;
      color: #553e90;
    }
    &.swiper-button-disabled {
      opacity: 0.3;
    }
  }
}

.card {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  .img-holder {
    position: relative;
    width: 100%;
    border-radius: 12px 0 0 12px;
    background-color: #eee;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .card-content {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 3.5rem 2.5rem;
    .title-card {
      font-family: fontBold;
      color: #4d5460;
      font-size: 2.5em;
      letter-spacing: 8px;
    }
    .title-holder img {
      max-width: 200px;
      max-height: 50px;
    }
    .platform-holder {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .platform-title {
        font-size: 1.6em;
        color: #4d5460;
        font-family: fontLight;
      }
      .platforms {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        margin-top: 15px;
        .platform {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 16px;
          border-radius: 5px;
          color: #fff;
          font-size: 1.2em;
          font-family: fontLight;
        }
      }
    }
  }
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    .img-holder {
      height: 200px;
      border-radius: 12px 12px 0 0;
    }
    .card-content {
      padding: 1.5rem 1rem;
    }
  }
}
</style>
