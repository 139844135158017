import axios from "axios";

let endpoint = "https://api.rubik.studio/api/";
if (process.env.NODE_ENV === "development")
  endpoint = "https://api.rubik.studio/api/";

const http = axios.create({
  baseURL: endpoint,
});

export default http;
