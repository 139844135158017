<script setup>
import { openWhatsapp } from "@/util";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import IntroSection from "@/components/home/IntroSection.vue";
import ShowCaseSection from "@/components/home/ShowCaseSection.vue";
import FormSection from "@/components/home/FormSection.vue";
import WhatsappIcon from "vue-material-design-icons/Whatsapp.vue";

const whatsapp = () => {
  openWhatsapp();
};
</script>

<template>
  <div>
    <Header />
    <main>
      <IntroSection />
      <section class="segments">
        <img
          class="block mobile-hidden"
          src="@/assets/images/block.png"
          alt="block"
        />
        <img
          class="facebook-logo drawing mobile-hidden tablet-hidden"
          src="@/assets/images/facebook-logo.png"
          alt="facebook logo"
        />
        <img
          class="twitter-logo drawing mobile-hidden"
          src="@/assets/images/twitter-logo.png"
          alt="twitter logo"
        />
        <div class="container">
          <h1 class="title text-center">
            {{ $t("Software para todos os segmentos") }}
          </h1>
          <div v-if="$i18n.locale == 'br'" class="segments-holder">
            <div class="item">Educação</div>
            <div class="item">Ciência & Tecnologia</div>
            <div class="item">Transporte & Mobilidade</div>
            <div class="item">Saúde & Fitness</div>
            <div class="item">Agronegócio</div>
            <div class="item">Informação & Notícias</div>
          </div>
          <div v-if="$i18n.locale == 'en'" class="segments-holder">
            <div class="item">Science & Education</div>
            <div class="item">Technology</div>
            <div class="item">Transport & Mobility</div>
            <div class="item">Health & Fitness</div>
            <div class="item">Agribusiness</div>
            <div class="item">Information & News</div>
          </div>
        </div>
      </section>
      <section class="company">
        <img
          class="cylinder mobile-hidden tablet-hidden"
          src="@/assets/images/cylinder.png"
          alt="cylinder"
        />
        <img
          class="whatsapp-logo drawing mobile-hidden"
          src="@/assets/images/whatsapp-logo.png"
          alt="whatsapp logo"
        />
        <img
          class="amazon-logo mobile-hidden"
          src="@/assets/images/amazon-logo.png"
          alt="amazon logo"
        />
        <img
          class="youtube-logo mobile-hidden"
          src="@/assets/images/youtube-logo.png"
          alt="youtube logo"
        />
        <div class="container">
          <div class="block">
            <h1 class="title color2">
              {{ $t("A empresa") }}
            </h1>
            <p class="general-text">
              {{
                $t(
                  `Somos uma Software House especializada em aplicativos Android, iOS, sistemas web, com uma equipe de profissionais qualificados, sempre atualizados no mundo da tecnologia e preparados para trazer soluções tecnológicas de ponta.`
                )
              }}
            </p>

            <h1 class="title color2 mt-3" style="font-size: 2.4em">
              {{ $t("Soluções sob medida") }}
            </h1>
            <p class="general-text">
              {{
                $t(
                  "Desenhamos uma solução completa e exclusiva para a sua empresa de acordo com as necessidades específicas dela. Da identificação das necessidades, passando por todos os processos necessários até chegarmos juntos à melhor opção possível."
                )
              }}
            </p>

            <div class="flex flex-start mt-2">
              <div @click="whatsapp" class="btn icon">
                <span>{{ $t("Fale com um especialista") }}</span>
                <WhatsappIcon fillColor="#fff" />
              </div>
            </div>
          </div>
          <div class="block">
            <img class="room" src="@/assets/images/room.png" alt="room" />
            <img
              class="cube-1 drawing mobile-hidden"
              src="@/assets/images/cube-1.png"
              alt="cube"
            />
            <img
              class="cube-2 drawing mobile-hidden"
              src="@/assets/images/cube-2.png"
              alt="cube"
            />
            <img
              class="instagram-logo mobile-hidden tablet-hidden"
              src="@/assets/images/instagram-logo.png"
              alt="instagram-logo"
            />
          </div>
        </div>
      </section>
      <ShowCaseSection />
      <section class="partners">
        <div class="container">
          <div class="partners-title">
            <h1 class="title color2">{{ $t("Parceiros") }}</h1>
            <p class="general-text">
              {{
                $t("Alguns dos nossos parceiros que confiam em nosso trabalho!")
              }}
            </p>
          </div>
        </div>
        <div class="features-images"></div>

        <div class="divider desktop-hidden tablet-hidden"></div>
      </section>
      <FormSection />
    </main>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
section.segments {
  padding: 145px 0 50px 0;
  @media only screen and (max-width: 720px) {
    padding: 70px 0 50px 0;
  }
  .block {
    position: absolute;
    top: -95px;
    left: 0;
  }
  .facebook-logo {
    position: absolute;
    top: 90px;
    right: 60px;
    &.drawing {
      animation: float 3.8s ease infinite alternate;
    }
  }
  .twitter-logo {
    position: absolute;
    bottom: 0;
    left: 40px;
    &.drawing {
      animation: float 4.3s ease-in-out infinite alternate;
    }
  }
  .container {
    padding-bottom: 60px;
    border-bottom: 1px solid #eee;
  }
}
.segments-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 3.4em;
  margin: 3em auto 0 auto;
  .item {
    position: relative;
    font-size: 1.4em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 0.4em;
    &::before {
      position: relative;
      content: "";
      width: 5px;
      height: 22px;
      background-color: #333;
    }
    &:nth-child(1)::before {
      background-color: #0085f8;
    }
    &:nth-child(2)::before {
      background-color: #ff3b3b;
    }
    &:nth-child(3)::before {
      background-color: #cb1ae8;
    }
    &:nth-child(4)::before {
      background-color: #11c123;
    }
    &:nth-child(5)::before {
      background-color: #ffc400;
    }
    &:nth-child(6)::before {
      background-color: #f85300;
    }
  }
}

section.company {
  padding-top: 25px;
  .cylinder {
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateX(-50%);
  }
  .whatsapp-logo {
    position: absolute;
    bottom: -40px;
    left: 20%;
    transform: translateY(-20%);
    z-index: 99;
    &.drawing {
      animation: float 4.6s ease-in-out infinite alternate;
      animation-delay: 300ms;
    }
  }
  .amazon-logo {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateY(-50%);
  }
  .youtube-logo {
    position: absolute;
    bottom: -40px;
    right: 3%;
    transform: translateY(-10%);
    z-index: 99;
  }
  .container {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    gap: 3rem;
    @media only screen and (max-width: 720px) {
      display: flex;

      flex-direction: column;
    }
    .block {
      position: relative;
      width: 100%;
      @media only screen and (max-width: 720px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .room {
        position: relative;
        width: 100%;
        height: 100%;
        pointer-events: none;
        @media only screen and (min-width: 721px) {
          position: absolute;
          width: initial;
        }
      }
      .cube-1 {
        position: absolute;
        top: -15px;
        left: 25%;
        transform: translateY(-25%);
        &.drawing {
          animation: float 4.1s ease-in-out infinite alternate;
        }
      }
      .cube-2 {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateX(-50%);
        &.drawing {
          animation: float 4.9s ease-in-out infinite alternate;
        }
      }
      .instagram-logo {
        position: absolute;
        bottom: 30px;
        left: 45%;
        transform: translateY(-45%);
      }
    }
  }
}

section.partners {
  padding-top: 165px;
  padding-bottom: 10px;
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    .partners-title {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 720px) {
        text-align: center;
      }
    }
  }
  .features-images {
    position: relative;
    width: 100vw;
    height: 200px;
    margin-top: 40px;
    background-image: url("../assets/images/client.png");
    background-repeat: repeat-x;
    background-size: cover;
    animation: background-slide 900s linear infinite;
    @media screen and (max-width: 720px) {
      height: 120px;
    }
  }

  @keyframes background-slide {
    from {
      background-position-x: 0px;
    }
    to {
      background-position-x: -60000px;
    }
  }
}

@keyframes float {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  100% {
    transform: translate3d(0, -16px, 0) scale(1.1);
  }
}
</style>
