<script setup>
import { computed } from "vue";
import i18n from "@/locales/i18n";
import { openWhatsapp } from "@/util";
import MarkerIcon from "vue-material-design-icons/MapMarker.vue";
import EmailIcon from "vue-material-design-icons/EmailOpenOutline.vue";
import WhatsappIcon from "vue-material-design-icons/Whatsapp.vue";

const legalInfo = computed(() => {
  const year = new Date().getFullYear();
  if (i18n.global.locale == "br" || i18n.global.locale == "pt")
    return `© 2019 - ${year} Rubik Studio - Todos os direitos reservados`;

  return `© 2019 - ${year} Rubik Studio - All rights reserved`;
});

const whatsapp = () => {
  openWhatsapp();
};
</script>
<template>
  <footer>
    <div class="container">
      <div class="block">
        <router-link to="/">
          <img
            class="logo"
            src="../assets/images/rubik-studio-logo.png"
            alt="Anpii logo"
          />
        </router-link>
        <span v-if="$i18n.locale == 'br' || $i18n.locale == 'pt'"
          >Uma empresa de desenvolvimento web e mobile, atualizada, <br />
          descontraída, profissional, com soluções tecnológicas e
          criativas.</span
        >
        <span v-else
          >An up-to-date web and mobile development company <br />
          relaxed, professional, with technological and creative
          solutions.</span
        >
      </div>

      <div class="block">
        <div class="col-title">{{ $t("Sistemas") }}</div>
        <div class="itens">
          <span>{{ $t("Portais de notícias") }}</span>
          <span>{{ $t("Sites institucionais") }}</span>
          <span>E-Commerces</span>
          <span>{{ $t("EADs") }}</span>
          <span>Landing Pages</span>
          <span>{{ $t("Integrações") }}</span>
        </div>
      </div>

      <div class="block">
        <div class="col-title">{{ $t("Aplicativos") }}</div>
        <div class="itens">
          <span>Android & iOS</span>
          <span>Dashboards</span>
          <span>{{ $t("Vendas") }}</span>
          <span>{{ $t("Assinaturas") }}</span>
          <span>Chats</span>
        </div>
      </div>

      <div class="block">
        <div v-show="$i18n.locale == 'br'" class="col-title">
          Sobre a Rubik Studio
        </div>
        <div v-show="$i18n.locale == 'pt'" class="col-title">
          Sobre Rubik Studio
        </div>
        <div v-show="$i18n.locale == 'en'" class="col-title">About us</div>
        <div class="itens">
          <div class="icon">
            <MarkerIcon fillColor="#fff" />
            <span v-show="$i18n.locale == 'br'">Campinas - SP</span>
            <span v-show="$i18n.locale == 'en'">Campinas - SP / Brazil</span>
            <span v-show="$i18n.locale == 'pt'">Campinas - SP / Brasil</span>
          </div>
          <div class="icon">
            <EmailIcon fillColor="#fff" />
            <a href="mailto:contato@rubik.studio">contato@rubik.studio</a>
          </div>
          <div @click="whatsapp" class="icon pointer">
            <WhatsappIcon fillColor="#fff" />
            <span>Whatsapp: (11) 94979-9578</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dues">
      <span>{{ legalInfo }}</span>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
footer {
  position: relative;
  width: 100vw;
  padding: 120px 0 0 0;
  background-color: #1a1a1a;
  @media only screen and (max-width: 720px) {
    padding: 3em 0 0 0;
  }
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 2em 2em;
    flex-wrap: wrap;
    .block {
      position: relative;
      a {
        display: block;
        margin-bottom: 5px;
      }
      .itens {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 5px;
      }
    }
    .col-title {
      position: relative;
      color: #fff;
      font-family: fontBold;
      font-size: 1em;
      height: 32px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .icon {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      margin-bottom: 12px;
      gap: 10px;
      span,
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

span,
a {
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
}

.logo {
  max-width: 160px;
  @media only screen and (max-width: 720px) {
    max-width: 140px;
  }
}

.dues {
  position: relative;
  width: 100vw;
  padding: 2em 0;
  background-color: #0b0b0b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  span {
    font-family: fontLight;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 720px) {
  .container {
    flex-direction: column;
  }
}
</style>
