<template>
  <div>
    <header :class="{ fixed: fixedHeader }">
      <div class="container">
        <transition enter-active-class="animated zoomIn">
          <router-link to="/" v-show="alwaysShowLogo || fixedHeader">
            <h1 class="logo">Rubik Studio</h1>
          </router-link>
        </transition>
        <div class="languages-holder">
          <div
            @click="setLanguage('br')"
            :class="{ active: $i18n.locale == 'br' }"
          >
            pt-BR
          </div>
          <div class="split"></div>
          <div
            @click="setLanguage('pt')"
            :class="{ active: $i18n.locale == 'pt' }"
          >
            pt-PT
          </div>
          <div class="split"></div>
          <div
            @click="setLanguage('en')"
            :class="{ active: $i18n.locale == 'en' }"
          >
            ENG
          </div>
        </div>
      </div>
    </header>
    <div
      id="whatsappButton"
      class="whats-item"
      @click="whatsapp"
      target="_blank"
    >
      <WhatsappIcon fillColor="#fff" />
      <!-- <font-awesome-icon :icon="['fab', 'whatsapp']" /> -->
    </div>
  </div>
</template>
<script>
// import translate from "@/translate/index";
import { openWhatsapp } from "@/util";
import WhatsappIcon from "vue-material-design-icons/Whatsapp";
export default {
  name: "Header",
  // locales: translate,
  components: {
    WhatsappIcon,
  },
  props: {
    backgroundActive: {
      type: Boolean,
      default: false,
    },
    alwaysShowLogo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fixedHeader: false,
      language: "",
    };
  },
  mounted() {
    this.fixedHeader = this.backgroundActive;

    if (!this.backgroundActive) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
          this.fixedHeader = true;
        } else {
          this.fixedHeader = false;
        }
      });
    }
  },
  methods: {
    whatsapp() {
      openWhatsapp();
    },
    setLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss" scoped>
.whats-item {
  position: fixed;
  width: 48px;
  height: 48px;
  background-color: var(--rubik-green);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 3%;
  bottom: 20px;
  z-index: 99;
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  svg {
    color: #f0f0f0;
    font-size: 2em;
    pointer-events: none;
  }
}

header {
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  background-color: transparent;
  z-index: 999;
  transition: background-color 0.2s ease;
  &.fixed {
    background: #0d1017;
    h1.logo {
      background: url("../assets/images/rubik-studio-logo.png") no-repeat;
    }
    .languages-holder div {
      color: #f0f0f0;
      &.active {
        color: var(--rubik-yellow);
        font-family: fontBold;
      }
    }
    .icon-menu {
      color: #f0f0f0;
    }
  }
}

header .container {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
  @media only screen and (max-width: 720px) {
    justify-content: center;
  }
  h1.logo {
    position: relative;
    width: 168px;
    height: 44px;
    background: url("../assets/images/rubik-studio-logo.png") no-repeat;
    background-size: cover !important;
    text-indent: -9999px;
    overflow: hidden;
    margin-right: auto;
    transition: opacity 0.2s ease;
  }
}

.languages-holder {
  position: relative;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  gap: 0.5rem;
  .split {
    position: relative;
    width: 1px;
    height: 12px;
    background-color: #ffffff44;
  }
}

.languages-holder div {
  font-family: fontLight;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  font-size: 1.1em;
}

.languages-holder div.active {
  color: var(--rubik-yellow);
  font-family: fontBold;
}

.icon-menu {
  font-size: 1.8em;
  color: #f0f0f0;
  cursor: pointer;
}

nav,
nav ul {
  position: absolute;
  width: 100vw;
  max-width: 280px;
  display: block;
  margin: 15px 10px 0 10px;
  border-radius: 5px;
  right: -10px;
}

ul {
  box-shadow: 1px 3px 10px -1px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

nav ul li {
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  padding: 0 15px;
  border-bottom: 1px solid #eee;
}

nav ul li a,
nav ul li span {
  font-size: 1.3em;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.2s ease;
}

nav ul li a:hover,
nav ul li span:hover {
  color: var(--rubik-yellow);
}

/* Mobile */
@media only screen and (max-width: 720px) {
  header h1.logo {
    width: 135px;
    height: 35px;
  }
}
</style>
