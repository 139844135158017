const openWhatsapp = () => {
  window.innerWidth > 719
    ? window.open(
        `https://web.whatsapp.com/send?phone=5511949799578&text=Olá! Estou no site de vocês e gostaria de mais informações.`
      )
    : window.open(
        `https://api.whatsapp.com/send?phone=555511949799578&text=Olá! Estou no site de vocês e gostaria de mais informações.`
      );
}

export { openWhatsapp };