<script setup>
import { ref } from "vue";
import http from "@/http";
import { openWhatsapp } from "@/util";
import validators from "@/util/validators";
import WhatsappIcon from "vue-material-design-icons/Whatsapp.vue";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import PhoneIcon from "vue-material-design-icons/Cellphone.vue";
import SendIcon from "vue-material-design-icons/Send.vue";

const form = ref({ email: "", name: "", phoneNumber: "", message: "" });
const showSuccessfulMsg = ref(false);
const warning = ref("");
const sendingForm = ref(false);

const submitForm = () => {
  sendingForm.value = true;
  http
    .post("contact", form.value)
    .then((response) => {
      if (response.status === 200) {
        showSuccessfulMsg.value = true;
        form.value.name = "";
        form.value.email = "";
        form.value.message = "";
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      sendingForm.value = false;
    });
};

const validateForm = () => {
  if (!form.value.name) {
    warning.value = "Por favor, informe o seu nome.";
    return;
  }
  if (!validators.validateEmail(form.value.email)) {
    warning.value = "Por favor, preencha seu e-mail corretamente.";
    return;
  }
  if (form.value.message.length < 10) {
    warning.value = "Por favor, digite a sua mensagem.";
    return;
  }

  warning.value = "";

  submitForm();
};

const whatsapp = () => {
  openWhatsapp();
};
</script>

<template>
  <section class="form">
    <div class="container shadow-default">
      <img
        class="rocket mobile-hidden"
        src="@/assets/images/rocket.png"
        alt="foguete"
      />
      <div class="forms-title">
        <h1 class="title color2">{{ $t("Alguma dúvida?") }}</h1>
        <p class="general-text">
          {{
            $t(
              "Fale com um de nossos especialistas e entenda melhor como podemos ajudar a sua empresa."
            )
          }}
        </p>
      </div>
      <div class="contact">
        <div @click="whatsapp" class="btn border icon">
          <span>{{ $t("Chamar no Whatsapp") }}</span>
          <WhatsappIcon fillColor="var(--rubik-green)" />
        </div>
        <div class="contacts-holder">
          <div class="item">
            <EmailIcon fillColor="#4D5460" />
            <a href="mailto:contato@rubik.studio">contato@rubik.studio</a>
          </div>
          <div class="item">
            <PhoneIcon fillColor="#4D5460" />
            <span>(11) 94979-9578</span>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="inputs-holder">
        <div class="flex-column">
          <div class="input-item">
            <span>{{ $t("Nome") }} *</span>
            <input v-model="form.name" type="text" />
          </div>
          <div class="input-item mt-1">
            <span>E-mail *</span>
            <input v-model="form.email" type="text" />
          </div>
        </div>
        <div class="flex-column">
          <div class="input-item">
            <span>{{ $t("Mensagem") }} *</span>
            <textarea v-model="form.message"></textarea>
          </div>
        </div>
      </div>
      <transition enter-active-class="animated zoomIn">
        <div v-show="warning" class="warning-msg">{{ warning }}</div>
      </transition>
      <transition enter-active-class="animated zoomIn">
        <div v-show="showSuccessfulMsg" class="success-msg">
          {{
            $t(
              "Recebemos sua mensagem. Em breve entraremos em contato. Obrigado!"
            )
          }}
        </div>
      </transition>
      <div class="flex-end">
        <div @click="validateForm" class="btn icon bg-send">
          <span v-show="!sendingForm">{{ $t("Enviar") }}</span>
          <SendIcon v-show="!sendingForm" fillColor="#fff" />
          <div class="loading" v-show="sendingForm"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section.form {
  position: relative;
  width: 100vw;
  padding: 0px;
  @media only screen and (max-width: 720px) {
    padding: 25px 0 65px 0;
  }
  .container {
    position: relative;
    bottom: -60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    border-radius: 12px;
    padding: 3em 8em;
    z-index: 5;
    background-color: #fff;
    @media only screen and (min-width: 1140px) {
      width: fit-content !important;
    }
    @media only screen and (min-width: 721px) and (max-width: 1139px) {
      padding: 3em 4em;
    }
    @media only screen and (max-width: 720px) {
      box-shadow: none;
      background-color: transparent;
      padding: 0;
      bottom: 0;
    }
    .forms-title {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 720px) {
        text-align: center;
      }
    }
    .contact {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media only screen and (max-width: 720px) {
        flex-direction: column;
      }
      .contacts-holder {
        position: relative;
        display: flex;
        width: fit-content;
        gap: 30px;
        @media only screen and (min-width: 721px) and (max-width: 1139px) {
          gap: 15px;
        }
        @media only screen and (max-width: 720px) {
          gap: 20px;
          margin-top: 20px;
          flex-direction: column;
        }
        .item {
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            color: #4d5460;
          }
        }
      }
    }
  }
}

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  margin: 0 auto;
}

.inputs-holder {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  vertical-align: top;
  gap: 60px;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    gap: 15px;
  }
  .flex-column {
    width: 50%;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
}

.input-item {
  position: relative;
  width: 100%;
  margin: 0;

  span {
    position: relative;
    font-family: fontLight;
    display: block;
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: #757575;
  }

  input,
  select,
  option {
    position: relative;
    font-family: fontLight;
    font-size: 1.1rem;
    width: 100%;
    height: 40px;
    margin: 5px 0 0 0;
    padding: 0 10px;
    border-radius: 4px;
    background-color: #eeeeee;
    color: var(--dark3) !important;
  }
  textarea {
    position: relative;
    width: 100%;
    height: 114px;
    border-radius: 4px;
    border-bottom: 1px solid #eeeeee;
    background-color: #eeeeee;
    font-family: fontRegular;
    font-size: 1.1rem;
    margin: 5px 0 0 0;
    padding: 0.6rem;
    color: var(--dark3);
    resize: none;
    &::-webkit-scrollbar {
      width: 0.2em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--rubik-blue);
      outline: 1px solid slategrey;
    }
  }
}

.rocket {
  position: absolute;
  top: -40px;
  right: -90px;
  width: 160px;
  transform: scaleX(-1);
  pointer-events: none;
  @media only screen and (min-width: 721px) and (max-width: 1139px) {
    top: -40px;
    right: -20px;
    width: 110px;
  }
  @media only screen and (max-width: 720px) {
    top: -60px;
    right: -10px;
    width: 90px;
  }
}

@media only screen and (max-width: 720px) {
  .flex-end {
    justify-content: center;
  }
}

.warning-msg {
  text-align: center;
  margin: 1rem auto 0 auto;
  font-size: 1.1rem;
}
.success-msg {
  text-align: center;
  margin: 1rem auto 0 auto;
  font-size: 1.2rem;
  color: var(--rubik-green);
}
</style>
