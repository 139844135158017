<script setup>
import { ref } from "vue";
import { openWhatsapp } from "@/util";
import MobileApp from "@/components/MobileApp.vue";
import WhatsappIcon from "vue-material-design-icons/Whatsapp.vue";

const whatsapp = () => {
  openWhatsapp();
};
</script>

<template>
  <section class="intro">
    <div class="blob one">
      <svg
        viewBox="0 0 800 500"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="100%"
        id="blobSvg"
      >
        <g transform="translate(136.62166595458984, 1.3602943420410156)">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style="stop-color: rgb(54, 85, 199)"></stop>
              <stop
                offset="100%"
                style="stop-color: rgb(123 62 229 / 30%)"
              ></stop>
            </linearGradient>
          </defs>
          <path fill="url(#gradient)">
            <animate
              attributeName="d"
              dur="16s"
              repeatCount="indefinite"
              values="M434.5,296Q409,342,373.5,372Q338,402,294,415Q250,428,192,440Q134,452,90.5,409.5Q47,367,26,308.5Q5,250,40.5,199.5Q76,149,116,118Q156,87,203,75Q250,63,301,68.5Q352,74,383.5,114.5Q415,155,437.5,202.5Q460,250,434.5,296Z;
              M431,301Q426,352,392.5,395Q359,438,304.5,436Q250,434,200.5,428Q151,422,118.5,383.5Q86,345,50,297.5Q14,250,28.5,190Q43,130,101,111Q159,92,204.5,73Q250,54,306,54.5Q362,55,407,94Q452,133,444,191.5Q436,250,431,301Z;
              M418.5,294Q402,338,385.5,397Q369,456,309.5,452.5Q250,449,204,429.5Q158,410,116.5,380.5Q75,351,40,300.5Q5,250,31,194.5Q57,139,105,110.5Q153,82,201.5,74Q250,66,308,57.5Q366,49,398.5,97Q431,145,433,197.5Q435,250,418.5,294Z;
              M464,310.5Q459,371,416.5,418Q374,465,312,449.5Q250,434,190.5,445.5Q131,457,83.5,415.5Q36,374,49,312Q62,250,49.5,188.5Q37,127,87,90.5Q137,54,193.5,52Q250,50,300.5,62.5Q351,75,381.5,116Q412,157,440.5,203.5Q469,250,464,310.5Z;
              M434.5,296Q409,342,373.5,372Q338,402,294,415Q250,428,192,440Q134,452,90.5,409.5Q47,367,26,308.5Q5,250,40.5,199.5Q76,149,116,118Q156,87,203,75Q250,63,301,68.5Q352,74,383.5,114.5Q415,155,437.5,202.5Q460,250,434.5,296Z"
            ></animate>
          </path>
        </g>
      </svg>
    </div>
    <div class="container">
      <div class="block">
        <h1 v-if="$i18n.locale == 'br'">
          Aplicativos <strong>Android</strong> e <strong>iOS</strong>, sistemas
          <strong>WEB</strong> e mais!
        </h1>
        <h1 v-if="$i18n.locale == 'en'">
          <b>Android</b>, <b>iOS</b>, <b>WEB</b> apps, <b>websites</b> and more!
        </h1>
        <h1 v-if="$i18n.locale == 'pt'">
          Aplicações <strong>Android</strong> e <strong>iOS</strong>, sistemas
          <strong>WEB</strong> e mais!
        </h1>

        <p v-if="$i18n.locale == 'br'">
          Desenvolvemos apps, sistemas, sites, plataformas por assinatura,
          soluções com inteligência artificial, integrações entre sistemas,
          bluetooth e muito mais!
        </p>

        <p v-if="$i18n.locale == 'en'">
          We develop apps, systems, websites, subscription platforms, artificial
          intelligence solutions, integration between systems and platforms,
          bluetooth and much more!
        </p>

        <p v-if="$i18n.locale == 'pt'">
          Desenvolvemos aplicações, sistemas, websites, plataformas de
          subscrição, soluções com inteligência artificial, integrações entre
          sistemas, Bluetooth e muito mais!
        </p>
        <div
          v-if="$i18n.locale == 'br'"
          @click="whatsapp"
          class="btn icon bg-rubik-yellow"
        >
          <span class="color-dark2">Fale com a gente</span>
          <WhatsappIcon fillColor="var(--dark2)" />
        </div>
        <div
          v-if="$i18n.locale == 'en'"
          @click="whatsapp"
          class="btn icon bg-rubik-yellow"
        >
          <span class="color-dark2">Get in touch</span>
          <WhatsappIcon fillColor="var(--dark2)" />
        </div>
        <div
          v-if="$i18n.locale == 'pt'"
          @click="whatsapp"
          class="btn icon bg-rubik-yellow"
        >
          <span class="color-dark2">Fale connosco</span>
          <WhatsappIcon fillColor="var(--dark2)" />
        </div>
      </div>
      <div class="block mobile-hidden">
        <MobileApp />
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.blob {
  position: absolute;
  width: 1900px;
  top: -330px;
  right: -400px;
  transform: rotate(180deg);
  opacity: 0.75;
}
section.intro {
  position: relative;
  width: 100vw;
  height: 700px;
  background: radial-gradient(100% 100% at 50% 100%, #1b2735 0%, #090a0f 100%);
  .container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: 720px) {
      position: relative;
      display: flex;
      width: 90%;
      margin: 0 auto;
    }
    .block {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      align-items: flex-start;
      @media only screen and (max-width: 720px) {
        align-items: center;
        padding-right: 0 !important;
        width: 100%;
        text-align: center;
        p {
          padding-right: 0 !important;
        }
      }
      &:first-child {
        padding-right: 4em;
      }
      h1 {
        font-family: fontLight;
        font-weight: lighter;
        font-size: 3.5em;
        color: #f1f1f1;
        letter-spacing: 5px;
        strong,
        b {
          font-size: 1em;
          color: #f1f1f1;
        }
      }
      p {
        font-family: fontLight;
        font-size: 1.15em;
        color: #f1f1f1;
        letter-spacing: 2px;
        line-height: 1.6em;
        margin-top: 20px;
        padding-right: 4em;
      }
      .btn {
        margin-top: 25px;
      }
      img.geek {
        position: absolute;
        bottom: -50px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
}
</style>
